<template>
  <div class="lcf-llp-withdraw">
    <NavBar
      title="提现"
      left-arrow
      fixed
      @click-left="$router.replace({name: 'User'})"
      placeholder
    ></NavBar>

    <Field :value="bankCardFormatter(bankCardNO)" input-align="right" readonly>
      <template #label>
        <div class="lcf-bank-card">银行卡</div>
      </template>
    </Field>

    <Field
      ref="withdraw"
      v-model="withdraw"
      class="lcf-input-withdraw"
      type="number"
      placeholder="请输入提现金额"
      input-align="right"
      @touchstart.native.stop="inputWithdrawAmount"
      :formatter="withdrawFormatter"
      format-trigger="onChange"
    >
      <template #label>
        <div class="lcf-withdraw-amount">提现金额</div>
      </template>
    </Field>

    <div class="lcf-balance">
      <div>当前可用余额</div>
      <div v-if="!balanceLoading">{{ balance }}</div>
      <Loading v-else size="12px" text-size="12px" text-color="#f03611">
        获取中...
      </Loading>
      <div>（元），</div>
      <div @click="fillWithdraw">全部提现</div>
    </div>

    <div class="lcf-withdraw-fee">
      <div>手续费</div>
      <div v-if="!feeLoading">{{ fee }}</div>
      <Loading v-else size="12px" text-size="12px" text-color="#f03611">
        获取中...
      </Loading>
      <div>（元）</div>
    </div>

    <Dialog
      v-model="confirm"
      title="提现"
      show-cancel-button
      @confirm="debounceWithdraw(withdraw)"
      class="lcf-dialog-withdraw"
    >
      <div class="lcf-dialog-withdraw-body-p1">
        <div>提现到</div>
        <div>{{ bankCardFormatter(bankCardNO) }}</div>
      </div>
      <div class="lcf-dialog-withdraw-body-p2">
        <div>金额</div>
        <div>￥{{ withdraw }}</div>
        <div></div>
      </div>
      <div class="lcf-dialog-withdraw-body-p3">
        <div>手续费</div>
        <div>￥{{ fee }}</div>
      </div>
    </Dialog>

    <NumberKeyboard
      v-model="withdraw"
      :show="show"
      theme="custom"
      extra-key="."
      close-button-text="提现"
      :close-button-loading="feeLoading || balanceLoading"
      @blur="
        () => {
          show = false
          forbiddenKeyboard()
        }
      "
      @close="showDialog"
      @input="forbiddenKeyboard"
      @delete="forbiddenKeyboard"
    >
    </NumberKeyboard>

    <div class="lcf-llp-withdraw-tips">
      温馨提示<br />
      1、提现手续费每笔1元；<br />
      2、每笔提现金额需大于当前提现手续费;<br />
      3、新用户需在最后一次充值日T+1后才能发起提现申请。
    </div>
    <div class="lcf-btn-withdraw">
      <Button
        round
        block
        type="primary"
        @click="showDialog"
        v-waves
        :loading="feeLoading || balanceLoading"
      >
        提现
      </Button>
    </div>

    <Popup
      v-if="show || unmount"
      v-model="popupShow"
      round
      position="bottom"
      @closed="closed"
      @close="close"
      :style="{ height: '50%' }"
      safe-area-inset-bottom
      transition-appear
    >
      <div class="lcf-withdraw-success-steps">
        <Steps direction="vertical" :active="1">
          <Step>
            <p>发起提现</p>
            <p>{{ dateFormatter(applyWithdrawTime) }}</p>
          </Step>
          <Step>
            <p>申请提现成功</p>
            <p>{{ dateFormatter(successWithdrawTime) }}</p>
          </Step>
        </Steps>
      </div>
      <div class="lcf-withdraw-success-tips">
        <div>
          <span>提现金额</span>
          <span>￥{{ withdraw }}</span>
        </div>
        <div>
          <span>到账银行卡</span>
          <span>{{ `尾号 ${bankCardNO.slice(-4)}` }}</span>
        </div>
        <div>
          <span>手续费</span>
          <span>￥{{ fee }}</span>
        </div>
      </div>
      <div class="lcf-withdraw-success-buttons">
        <Button round block type="primary" @click="finishWithdraw" v-waves>
          完成
        </Button>
      </div>
    </Popup>
  </div>
</template>

<script>
import {
  NavBar,
  Field,
  NumberKeyboard,
  Notify,
  Button,
  Loading,
  Dialog,
  Popup,
  Steps,
  Step,
} from 'vant'
import { mapActions } from 'vuex'
import Utils from '@/utils'
import Decimal from 'decimal.js'

export default {
  components: {
    NavBar,
    Field,
    NumberKeyboard,
    Button,
    Loading,
    Popup,
    Steps,
    Step,
  },
  data() {
    return {
      balance: '-',
      bankCardNO: '', //'-',
      withdraw: '',
      fee: '-',

      show: false,
      confirm: false,

      balanceLoading: true,
      feeLoading: false,

      // 请求的Promise
      balancePromise: null,
      feePromise: null,

      // 提现接口请求的防抖
      debounceWithdraw: null,

      // popup控制字段
      popupShow: false,
      unmount: true,

      applyWithdrawTime: null,
      successWithdrawTime: null,
    }
  },
  created() {
    // 提现接口请求的防抖
    this.debounceWithdraw = Utils.debounce(this.postWithdraw, 1000, true)
  },
  mounted() {
    this.disablePhoneKeyboard()
    this.show = true

    this.balancePromise = this.showBalance()
    this.showBankCardInfo()
    let watchCb = Utils.debounce(
      function (nv, ov) {
        if (!nv) {
          this.fee = '-'
        }
        if (nv !== ov && nv > 0) {
          this.feeLoading = true
          this.feePromise = this.getFee(nv)
        }
      },
      600,
      false
    )

    let unwatch = this.$watch('withdraw', watchCb)

    this.$once('hook:beforeDestroy', () => {
      unwatch()
    })
  },
  methods: {
    ...mapActions([
      'requestLlpWithdraw',
      'requestLlpWithdrawFee',
      'requestBankCard',
      'requestAccountInfo',
    ]),
    disablePhoneKeyboard() {
      const el = this.$refs.withdraw.$el.querySelector('input')
      const exec = () => {
        el.readOnly = true
        setTimeout(() => {
          el.readOnly = false
        }, 200)
      }
      el.addEventListener('focus', exec)
      el.addEventListener('click', exec)
    },
    async showBankCardInfo() {
      let error, data
      ;[error, data] = await to(this.requestBankCard())
      if (error) {
        Notify({
          type: 'warning',
          message: '获取银行卡信息失败',
        })
      }
      if (data?.account) {
        this.bankCardNO = data.account
      } else {
        Notify({
          type: 'warning',
          message: '当前账户未绑定银行卡，无法提现',
        })
        setTimeout(() => {
          this.$router.push({ name: 'User' })
        }, 300)
      }
    },
    inputWithdrawAmount(e) {
      this.show = true
    },
    async showDialog() {
      // 等待watch执行后
      await new Promise((resolve) => {
        setTimeout(resolve, 600)
      })
      if (this.feeLoading || this.balanceLoading) {
        return
      }
      if (!this.withdraw || this.withdraw == 0) {
        Dialog.alert({
          title: '温馨提示',
          message: '请输入提现金额',
        })
      } else if (this.fee === '-') {
        // 手续费还未加载完成
        Notify({
          type: 'warning',
          message: '请耐心等待手续费查询完成~',
        })
      } else if (this.balancePromise && this.feePromise) {
        // 余额和手续费查询完成后 才能弹出
        let error, result
        ;[error, result] = await to(
          Promise.all([this.balancePromise, this.feePromise])
        )

        if (error) {
          Notify({
            type: 'warning',
            message: error.message || error,
          })
        }

        if (result.length === 2) {
          this.confirm = true
        }
      }
    },
    async getFee(val) {
      let error, data
      ;[error, data] = await to(this.requestLlpWithdrawFee(val))

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (data?.fee && this.withdraw === val) {
        this.fee = data.fee
        this.feeLoading = false
      }
      return data
    },
    async postWithdraw(val) {
      this.applyWithdrawTime = new Date()
      let error, data
      ;[error, data] = await to(this.requestLlpWithdraw(val))

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (data) {
        this.successWithdrawTime = new Date()
        this.popupShow = true
        this.unmount = true
      }

      return data
    },

    async showBalance() {
      let error, data
      ;[error, data] = await to(this.requestAccountInfo())

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      } else {
        this.balance = data.balance
        // 如果已经输入的提现金额大于balance
        new Decimal(this.withdraw || '0').greaterThan(
          new Decimal(this.balance)
        ) && (this.withdraw = this.balance + '')
      }
      this.balanceLoading = false
    },
    withdrawFormatter(value) {
      // 输入值小于可用余额
      if (value && this.balance != '-') {
        let v = new Decimal(value)
        let balance = new Decimal(this.balance)
        if (v.greaterThan(balance)) {
          value = balance.toString()
        }
      }

      // 总长度(字符)不大于12
      if (value.length > 12) {
        value = value.substring(0, value.length - 1)
      }

      // 大于等于3的小数位直接舍弃
      if (/^\d+\.\d{3,}$/.test(value)) {
        let str = new Decimal(value).toFixed(3)
        value = str.substring(0, str.length - 1)
      }

      // 删除首尾无意义的0
      value = value.replace(/^(0+)([\d.])/, (match, p1, p2) =>
        p2 === '.' ? '0' + p2 : p2
      )
      value = value.replace(/(.\d)(0)$/, (match, p1) => (p1 === '.0' ? '' : p1))

      return value
    },
    fillWithdraw() {
      this.withdraw = this.balance
    },
    closed() {
      this.unmount = false
    },
    close() {
      this.show = true

      this.balancePromise = this.showBalance()
      this.showBankCardInfo()
    },
    finishWithdraw() {
      this.popupShow = false
      this.$router.push({ name: 'User' })
    },
    async forbiddenKeyboard() {
      const el = this.$refs.withdraw.$el.querySelector('input')
      await this.$nextTick()
      el.readOnly = true
      setTimeout(() => {
        el.readOnly = false
      }, 200)
    },
  },
  computed: {
    bankCardFormatter() {
      return (card_id) => {
        let ret = card_id
        try {
          ret = ret
            .split('')
            .map((v, i) => {
              let value = i < card_id.length - 4 ? '*' : v
              if ((i + 1) % 4 === 0) {
                return value + ' '
              }
              return value
            })
            .join('')
        } catch (e) {
          //
        }
        return ret
      }
    },
    dateFormatter() {
      return (date) => {
        if (Object.prototype.toString.call(date) !== '[object Date]') {
          return ''
        }
        let year = date.getFullYear(),
          month = (date.getMonth() + 1 + '').padStart(2, 0),
          day = (date.getDate() + '').padStart(2, 0),
          hour = (date.getHours() + '').padStart(2, 0),
          minute = (date.getMinutes() + '').padStart(2, 0),
          second = (date.getSeconds() + '').padStart(2, 0)
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .van-cell--clickable:active {
  background-color: transparent !important;
}
.lcf-llp-withdraw {
  font-size: 16px;
  .lcf-bank-card {
    background: border-box right / 16px auto no-repeat
      url('~@/assets/img/icon_bankpay.png');
  }
  .lcf-withdraw-amount {
    background: border-box right / 16px auto no-repeat
      url('~@/assets/img/ic_income_amount.png');
    height: 48px;
    line-height: 48px;
  }
  .lcf-input-withdraw {
    ::v-deep input {
      height: 48px;
      font-size: 32px;
      line-height: 48px;
      &::placeholder {
        font-size: 14px;
      }
      &::-webkit-input-placeholder {
        transform: translate(0, -33%);
      }
    }
  }
  .lcf-llp-withdraw-tips {
    font-size: 12px;
    color: $lcf-color-text-gray;
    padding: 16px;
    line-height: 32px;
  }
  .lcf-balance {
    font-size: 12px;
    color: $lcf-color-text-gray;
    display: flex;
    flex-flow: row nowrap;
    padding: 8px 16px;
    div:nth-child(1) {
      width: 96px;
    }
    div:nth-child(2),
    div:nth-child(4) {
      color: $lcf-color-default;
    }
  }
  .lcf-withdraw-fee {
    font-size: 12px;
    color: $lcf-color-text-gray;
    display: flex;
    flex-flow: row nowrap;
    padding: 8px 16px;
    div:nth-child(1) {
      width: 96px;
    }
    div:nth-child(2) {
      color: $lcf-color-default;
    }
  }
  .lcf-dialog-withdraw {
    .lcf-dialog-withdraw-body-p1,
    .lcf-dialog-withdraw-body-p2,
    .lcf-dialog-withdraw-body-p3 {
      padding: 16px;
      display: flex;
      flex-flow: row nowrap;
    }
    .lcf-dialog-withdraw-body-p1 {
      justify-content: space-between;
      font-size: 14px;
      div:last-child {
        background: border-box left / 16px auto no-repeat
          url('~@/assets/img/icon_bankpay.png');
        padding: 0 0 0 24px;
      }
    }
    .lcf-dialog-withdraw-body-p2 {
      font-size: 32px;
      text-align: center;
      line-height: 32px;
      & > div:first-child {
        font-size: 14px;
        text-align: left;
      }
      & > div {
        flex: 1 1;
      }
    }
    .lcf-dialog-withdraw-body-p3 {
      font-size: 14px;
      justify-content: space-between;
    }
  }
  .lcf-btn-withdraw {
    bottom: 0;
    position: absolute;
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  .lcf-withdraw-success-steps {
    padding: 0 16px;
  }
  .lcf-withdraw-success-tips {
    padding: 0 16px;
    font-size: 14px;
    & > div {
      display: flex;
      justify-content: space-between;
      padding: 4px 0;
    }
  }
  .lcf-withdraw-success-buttons {
    padding: 4px 16px;
    width: 100%;
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
  }
}
</style>
